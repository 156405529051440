import logo from './david.webp';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          <h1> David Collett </h1>
          <a
            className="App-link"
            href="https://www.taxpayersparty.com.au/open-letter/rba-full-reserve-banking/"
            target="_self"
            rel="noopener noreferrer"
          > letters </a>
          <a
            className="App-link"
            href="https://www.meditation2relax.com"
            target="_self"
            rel="noopener noreferrer"
          > meditation </a>
          <a
            className="App-link"
            href="https://www.taxpayersparty.com.au"
            target="_self"
            rel="noopener noreferrer"
          > policy </a>
        </div>
      </header>
    </div>
  );
}

export default App;
